import { ContentType, HttpClient, RequestParams } from './base/BaseHttpClient'
import { useHttpClient } from './base/HttpClient'
import {
   PaymentConfirmation,
   StartPaymentRequest,
   SubscriptionDto
} from './contracts/models'

export class SubscriptionApi<SecurityDataType = unknown> {
   http: HttpClient<SecurityDataType>

   constructor(http: HttpClient<SecurityDataType>) {
      this.http = http
   }

   /**
    * No description
    *
    * @tags Subscription
    * @name GetUserSubscription
    * @request GET:/api/subscription
    * @secure
    * @response `200` `SubscriptionDto` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getUserSubscription = (params: RequestParams = {}) =>
      this.http
         .request<SubscriptionDto, void>({
            path: `/api/subscription`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data)
   /**
    * No description
    *
    * @tags Subscription
    * @name StartPayment
    * @request POST:/api/subscription/payment/start
    * @secure
    * @response `200` `string` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   startPayment = (data: StartPaymentRequest, params: RequestParams = {}) =>
      this.http
         .request<string, void>({
            path: `/api/subscription/payment/start`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
         })
         .then((m) => m.data)
   /**
    * No description
    *
    * @tags Subscription
    * @name VerifyPayment
    * @request POST:/api/subscription/payment/verify
    * @secure
    * @response `200` `void` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   verifyPayment = (data: PaymentConfirmation, params: RequestParams = {}) =>
      this.http
         .request<void, void>({
            path: `/api/subscription/payment/verify`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
         })
         .then((m) => m.data)
}

const subscriptionApi = new SubscriptionApi(useHttpClient())
export function useSubscriptionApi(): SubscriptionApi {
   return subscriptionApi
}
