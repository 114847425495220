import { useEventBus } from './useEventBus'

export function useFreshDesk() {
   const freshDesk = (window as any).FreshworksWidget
   const hideLauncher = () => {
      freshDesk('hide', 'launcher')
   }
   const openWidget = () => {
      freshDesk('open')
      useEventBus().emit('helpOpened')
   }
   return {
      hideLauncher,
      openWidget
   }
}
